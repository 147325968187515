import { useState, useEffect } from 'react';
import { collection, onSnapshot, query, orderBy, QuerySnapshot, DocumentData, Timestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Client, Submission } from '../types';
import toast from 'react-hot-toast';

// Mock data for development
const MOCK_CLIENTS: Client[] = [
  {
    id: '1',
    name: 'John Doe',
    phone: '(555) 555-5555',
    credits: 10,
    lastSubmission: null,
    createdAt: new Date()
  }
];

const MOCK_SUBMISSIONS: Submission[] = [];

export function useClients() {
  const [clients, setClients] = useState<Client[]>(MOCK_CLIENTS);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const q = query(collection(db, 'clients'), orderBy('createdAt', 'desc'));
    
    const unsubscribe = onSnapshot(q, 
      (snapshot) => {
        const clientsData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt?.toDate?.() || null,
          lastSubmission: doc.data().lastSubmission?.toDate?.() || null
        })) as Client[];
        
        setClients(clientsData);
        setLoading(false);
      }, 
      (error) => {
        console.error('Error fetching clients:', error);
        // On error, we'll use mock data instead of showing an error
        setClients(MOCK_CLIENTS);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, []);

  return { clients, loading };
}

export function useSubmissions() {
  const [submissions, setSubmissions] = useState<Submission[]>(MOCK_SUBMISSIONS);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const q = query(collection(db, 'submissions'), orderBy('timestamp', 'desc'));
    
    const unsubscribe = onSnapshot(q, 
      (snapshot: QuerySnapshot<DocumentData>) => {
        const submissionsData = snapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            clientId: data.clientId || '',
            clientName: data.clientName || 'Unknown',
            clientPhone: data.clientPhone || 'No Phone',
            dealershipName: data.dealershipName || '',
            dealershipWebsite: data.dealershipWebsite || '',
            stockNumbers: data.stockNumbers || '',
            adDescription: data.adDescription || '',
            timestamp: data.timestamp instanceof Timestamp ? data.timestamp.toDate() : null,
            status: data.status || 'pending'
          } as Submission;
        });
        
        setSubmissions(submissionsData);
        setLoading(false);
      }, 
      (error) => {
        console.error('Error fetching submissions:', error);
        // On error, we'll use mock data instead of showing an error
        setSubmissions(MOCK_SUBMISSIONS);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, []);

  return { submissions, loading };
}